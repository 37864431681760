<template>
    <div>
        <b-row class="mt-2">
            <b-col sm="6" md="6">
                <span class="details-view-title">DEPOSIT</span>
                <div class="details-view-subtitle">List of deposits within the month</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>

            <template v-slot:cell(dispatchNo)="row">
                <div class="numFont">
                    <strong>{{ row.item.dispatchNo }}</strong>
                </div>
            </template>

            <template v-slot:cell(quantity)="row">
                <div class="numFont">
                    <strong>{{ row.item.quantity }}</strong>
                </div>
            </template>
        </b-table>

        <b-row class="my-1">
            <b-col md="8" sm="12">
                <span class="total-display">Total:
                    <span class="numFont">{{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
                    &nbsp;|&nbsp;
                    Total Deposits: <span class="numFont">{{ totalDeposits }}</span>
                </span>
            </b-col>
            <b-col md="4" sm="12">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>

        <b-row class="mt-2" v-show="!isRetailer">
            <b-col lg="4" md="6" sm="12">
                <b-button variant="outline-primary" @click="gotToDispatch" class="mr-2">
                    View More
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// Others
import config from '@/config/env-constants';
import moment from 'moment';
import _ from 'lodash';


export default {
    name: 'deposit-summary',
    props: {
        dashboard: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'dispatchNo',
                    label: 'Dispatch No.',
                    sortable: true,
                },
                {
                    key: 'location',
                    label: 'Storage Location',
                    sortable: true,
                },
                {
                    key: 'quantity',
                    label: 'Quantity',
                    sortable: true,
                },
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            isSuperAdmin: this.$store.getters.isSuperAdmin,
            isRetailer: this.$store.getters.isRetailer,
            loggedUserCompany: this.$store.getters.loggedUserCompany,
        }
    },
    computed: {
        totalDeposits() {
            return _.sumBy(this.items, o => { return o.quantity; });
        }
    },
    watch: {
        dashboard: function () {
            this.items = this.dashboard.deposits;
            this.totalRows = this.items.length;
        }
    },
    methods: {
        gotToDispatch() {
            let currTimestamp = DateUtil.getCurrentTimestamp();
            let range = DateUtil.getMonthDateRange(currTimestamp);

            this.params = {
                filterBy: {
                    dateFrom: moment(range.start).format('YYYY-MM-DD'),
                    dateTo: moment(range.end).format('YYYY-MM-DD'),
                    sourceCompany: DropDownItemsUtil.getCompanyItem(this.loggedUserCompany),
                    destinationCompany: { ...config.companyDefaultValue },
                    status: null,
                    dispatchNo: '',
                    assetType: { ...config.assetTypeDefaultValue },
                    plateNo: { ...config.transportationDefaultValue },
                }
            };

            this.$store.dispatch('setDispatchParams', this.params);
            this.$store.dispatch('setCurrentDispatch', {});

            if (!this.isSuperAdmin) {
                this.$router.push({ path: '/dispatch' });
            } else {
                this.$router.push({ path: '/admin/admin-dispatch' });
            }
        },
    }
}
</script>

<style scoped></style>